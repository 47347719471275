// App.js
import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import '../../css/style.css';
import Header from '../Header/Header';
import Section1 from './LpSection1';
import Section2 from './LpSection2';
import Section3 from './LpSection3';
import Footer from '../Footer/Footer';
import { AuthContext } from '../AuthContext/AuthContext';

// const AuthPopup = lazy(() => import("../AuthentificationPopUp/AuthentificationPopUp"));
// const AccountPopup = lazy(() => import("../AccountPopUp/AccountPopUp"));
// const ResetPassword = lazy(() => import("../ResetPasswordPopUp/ResetPasswordPopUp"));
// const VerifyEmail = lazy(() => import("../VerificationMailPopUp/VerificationMailPopUp"));

import AuthPopup from '../AuthentificationPopUp/AuthentificationPopUp';
import AccountPopup from '../AccountPopUp/AccountPopUp';
import ResetPassword  from'../ResetPasswordPopUp/ResetPasswordPopUp';
import VerifyEmail  from'../VerificationMailPopUp/VerificationMailPopUp';


const LP_main = ({ popUpType }) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  const getOrientation = () => {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  };

  const [orientation, setOrientation] = useState(getOrientation());
  
  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const handleOpenPopup = () => {
  setPopupVisibility(true);
  };

  const handleClosePopup = () => {
  setPopupVisibility(false);
  };

  useEffect(() => {
    fetch('https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/connect', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (response.ok) {
                console.log('Connect');
            } else {
                console.error('Error connection');
            }
        })
        .catch(error => {
            console.error('Error connection: ', error);
        });

            // Vérifiez les paramètres de l'URL pour voir si la vérification a été réussie
    const params = new URLSearchParams(location.search);
    if (params.get('openPopUp')) {
      setPopupVisibility(true);
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Accueil - Stratup</title>
        <meta name="description" content="Explorez un monde d'opportunités en obtenant des parts d'objets de collection et de luxe exclusifs, d'oeuvres d'art uniques et de startups innovantes dès 10€." />
      </Helmet>
      <Header handleClosePopup={handleClosePopup} handleOpenPopup={handleOpenPopup} isLandingPage={popUpType ? 0 : 1} />
      <Section1 id='section1' handleClosePopup={handleClosePopup} handleOpenPopup={handleOpenPopup} />
      <Section2 id='section2' handleClosePopup={handleClosePopup} handleOpenPopup={handleOpenPopup} loading="lazy"/>
      <Section3 id='section3' handleClosePopup={handleClosePopup} handleOpenPopup={handleOpenPopup} loading="lazy"/>
      {isAuthenticated ? 
        isPopupVisible &&<AccountPopup onClose={handleClosePopup} isPopupVisible={isPopupVisible} loading="lazy"/>
      : isPopupVisible && <AuthPopup onClose={handleClosePopup} isPopupVisible={isPopupVisible} loading="lazy"/>
      }
      {popUpType === 'resetPassword'  && <ResetPassword loading="lazy"/>}
      {popUpType === 'verifyEmail'  && <VerifyEmail loading="lazy"/>}
      <Footer loading="lazy"/>
    </div>
  );
};

export default LP_main;

// AuthPopup.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import './AccountPopUp.css';
import { Carousel, Row, Col } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, XOutlined } from '@ant-design/icons';
import { AuthContext } from '../AuthContext/AuthContext';
import Collection from '../../optimized_media/obj_collection_w_back.avif';
import Luxe from '../../optimized_media/obj_luxe_w_back.avif';
import Art from '../../optimized_media/obj_art_w_back.avif';
import Immo from '../../optimized_media/obj_immo_w_back.png';


const AccountPopUp = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [message, setMessage] = useState('');
  const popupRef = useRef();
  const isSmallScreen = window.matchMedia('(max-width: 576px)').matches;

  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);

  //------------------------------------------------------------------------------------------------------------//
  /* Close when click outside of popup */
  //------------------------------------------------------------------------------------------------------------//
  const handleClickOutside = (event) => {
    if (popupRef.current && popupRef.current.contains(event.target.querySelector('.popup-content'))) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClose]);
  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//


  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated]);

  const fetchUserInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/user_info', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setUserInfo(data);
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
      setError('Erreur lors de la récupération des informations de l\'utilisateur.');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo) {
    return <div>Loading...</div>;
  }


  //------------------------------------------------------------------------------------------------------------//
  /* Sign In/Sign Up functions */
  //------------------------------------------------------------------------------------------------------------//

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleSignUp = () => {
  //   if (!email.trim() || !isValidEmail(email)) {
  //     setMessage(<span className="email-error2">Veuillez entrer une adresse e-mail valide.</span>);
  //     return;
  //   }
  //   fetch('https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/register', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email: email, password: password }),
  //   })
  //     .then((response) => {
  //       return response.json()
  //       .then((data) =>({ data, response })); // Continuer seulement si la réponse n'est pas 409
  //     })
  //     .then((result) => {
  //       if (!result) return; // Si result est null, sortir
  //       const { data, response } = result;
  //         if (data.message) {
  //           setMessage(<span className="email-message2">{data.message}</span>);
  //         } else {
  //           setMessage(<span className="email-error2">Erreur lors de l'inscription.</span>);
  //         }
  //     })
  //     .catch((error) => {
  //       console.error('Erreur lors de l\'inscription:', error);
  //       setMessage(<span className="email-error2">Erreur lors de l'inscription.</span>);
  //     });
  // };

  // const handleSignIn = () => {
  //   if (!email.trim() || !isValidEmail(email)) {
  //     setMessage(<span className="email-error2">Veuillez entrer une adresse e-mail valide.</span>);
  //     return;
  //   }
  //   fetch('https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/login', { // 'https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/login', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email: email, password: password }),
  //   })
  //     .then(async (response) => {
  //       const data = await response.json();
  //       if (response.ok) {
  //         if (data.access_token) {
  //           localStorage.setItem('token', data.access_token);
  //           setMessage(<span className="email-message2">Connexion réussie !</span>);
  //         } else {
  //           setMessage(<span className="email-error2">Erreur lors de la connexion.</span>);
  //         }
  //       } else if (response.status === 401) {
  //         setMessage(
  //           <span className="email-error2">
  //             {data.error}
  //             <br />
  //             <button className="button_signUp" id="submitBtn" onClick={handleResetRequest}>
  //               Réinitialiser votre mot de passe ?
  //             </button>
  //           </span>
  //         );
  //       } else {
  //         setMessage(<span className="email-error2">Erreur lors de la connexion.</span>);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Erreur lors de la connexion:', error);
  //       setMessage(<span className="email-error2">Erreur lors de la connexion.</span>);
  //     });
  // };
  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//



  //------------------------------------------------------------------------------------------------------------//
  /* Reset password request */
  //------------------------------------------------------------------------------------------------------------//

  const handleResetRequest = (email) => {
    fetch('https://stratup2025-gnh7euhyhwcbffgw.francecentral-01.azurewebsites.net/reset_password_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setMessage(<span className="email-message2">{data.message}</span>);
        } else {
          setMessage(<span className="email-error2">{data.error}</span>);
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la demande de réinitialisation:', error);
        setMessage(<span className="email-error2">Erreur lors de la demande de réinitialisation.</span>);
      });
  };

  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//


  return (
    <Row className="account-popup" ref={popupRef}>
      <Col xs={4} sm={4} md={2} lg={5} xl={5} />
      <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} />
        <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
          <h1>Mon compte</h1>
          <p>Email: {userInfo.email}</p>
          <button className="button_signUp" id="submitBtn" onClick={() => handleResetRequest(userInfo.email)}>
            Changer votre mot de passe ?
          </button>
          <p>{message}</p>
          <h2>Suivez-nous !</h2>
          <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <LinkedinOutlined />
          </a>
          <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <FacebookOutlined />
          </a>
          <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <InstagramOutlined />
          </a>
          <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <XOutlined />
          </a>
        </Col>
        <Col span={1}>{isSmallScreen && <div className="close-button" onClick={onClose}>X</div>}</Col>
        <Col xs={0} sm={0} md={13} lg={10} xl={10}>
          <Carousel autoplay className="image-slider">
            <img className="image" src={Collection} alt="Collection" />
            <img className="image" src={Luxe} alt="Luxe" />
            <img className="image" src={Art} alt="Art" />
            <img className="image" src={Immo} alt="Immo" />
          </Carousel>
          <div className="close-button" onClick={onClose}>
            X
          </div>
        </Col>
      </Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5} />
    </Row>
  );
};

export default AccountPopUp;
